<template>
  <div class="right">
    <div class="title">
      <span>关注的店铺</span>
    </div>
    <div class="content">
      <Loading v-if="isLoading" />
      <div class="store" v-else>
        <div class="tishi" v-if="list == ''">{{ tishi }}</div>

        <div class="flex" v-for="(item, index) in list" :key="index">
          <div class="flex">
            <div class="store-info">
              <div class="cancel-follow" @click="quxiao(item.storeId)">
                <span>取消关注</span>
              </div>
              <div class="store-logo">
                <div class="store-logo-img">
                  <Uimage :src="item.storeImg" />
                </div>
              </div>
              <div class="store-name">
                <span>{{ item.storeName }}</span>
              </div>
              <div class="store-attr">
                <div class="store-attr-lv">
                  <span>人气店铺</span>
                </div>
                |
                <div class="store-attr-tiem">
                  <span>{{ item.countStore }}人关注</span>
                </div>
              </div>

              <div class="store-button">
                <button class="store-in" @click="dianpu(item)">
                  <Uimage src="../../../assets/order/store-in.png" alt="" />
                  <div>进入店铺</div>
                </button>
                <button class="store-cs" @click="kefu()">
                  <Uimage src="../../../assets/order/store-cs.png" alt="" />
                  <div>联系客服</div>
                </button>
              </div>
            </div>
            <div class="store-wares">
              <div class="top">
                <div class="menu">
                  <div class="qiehuan">
                    <div class="daohang">店铺推荐</div>
                  </div>
                  <div class="piliang" @click="dianpu(item)">
                    更多<i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>

              <div class="store-wares-content">
                <div class="flex-item">
                  <div
                    class="wares-item"
                    v-for="(cx, c) in item.spuCXList"
                    :key="c"
                    @click="detail(item.storeId, cx.spuId)"
                  >
                    <div class="wares-item-img">
                      <Uimage :src="cx.url" />
                    </div>
                    <div class="wares-item-title">
                      <div class="wares-item-title-text">
                        <span>{{ cx.name }}</span>
                      </div>
                      <div class="wares-item-title-mony">
                        <span>{{ cx.price }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fenye" v-if="list != ''">
      <el-pagination
        @current-change="pagechange"
        :current-page="page.current"
        style="text-align: right;margin-top: 20px"
        background
        :total="page.total"
        :page-size="page.size"
        layout="prev, pager, next, jumper, ->, total"
      >
      </el-pagination>
    </div>
    </div>

  </div>
</template>
<script>
import follow_store_node from './follow-store-node';
import { post, get } from '@/utils/request';
export default {
  components: {
    'follow-store-node': follow_store_node,
  },
  data() {
    return {
      list: [],
      isLoading: true,
      pages: 1,
      mes: '',
      paihang: [
        {
          i: 'x',
          title: '热销',
        },
        {
          i: 'y',
          title: '新品',
        },
      ],
      TabIndex: 0,
      activeIndex: '',
      currentPage: 1,
      totalNum: 1,
      tishi: '空空如也~',
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
    };
  },
  created() {
    this.getorder();
  },
  methods: {
    detail(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    dianpu(e) {
      const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          storeId: e.storeId,
        },
      });
      window.open(href, '_blank');
    },
    kefu(e) {
      window.open(
              'https://cschat-ccs.aliyun.com/index.htm?tntInstId=o532_d88&scene=SCE00010277',
              '_blank'
      );
    },
    quxiao(e) {
      this.$confirm('是否取消关注?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        get('api/collectStore/cancelstore?storeId=' + e + '').then((res) => {
          this.getorder();
        });
      });
    },
    pagechange(p) {
      this.page.current = p;
      get('api/collectStore/selCollectStoreList?').then((res) => {
        this.list = res.data.data.list;
      });
    },
    getorder() {
      get('api/collectStore/selCollectStoreList?pageNum=1&pageSize=10').then(
        (res) => {
          this.list = res.data.data.list;
          this.page.total = res.data.data.total;
          this.isLoading = false;
        }
      );
    },
    selectedTabItem(w, store) {
      this.TabIndex = w.i + JSON.stringify(store);
    },
  },
};
</script>
<style lang="scss" scoped>
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 976px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  padding-right: 30px;

  .title {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }

  .content {
    margin-top: 30px;
  }
}
.flex {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}
.store {
  width: 100%;
  margin-top: 20px;
  .store-info {
    width: 232px;
    border: 1px solid #c3c3c3;
    position: relative;

    &:hover {
      .cancel-follow {
        display: inline-block;
      }

      .follow-handle {
        display: inline-block;
      }
    }

    .follow-handle {
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;

      &:checked {
        display: inline-block;
      }
    }

    .cancel-follow {
      display: none;
      position: absolute;
      width: 76px;
      height: 24px;
      right: 0px;
      top: 0px;
      background: #b0aeaf;
      border-radius: 0px 0px 0px 6px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      z-index: 2;
      cursor: pointer;
      line-height: 24px;
    }

    .store-logo {
      width: 100%;
      height: 113px;
      position: relative;

      .store-logo-img {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        margin: auto;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .store-name {
      font-size: 16px;
      font-weight: 600;
      height: 20px;
      width: 100%;
      text-align: center;
      line-height: 20px;
    }

    .store-attr {
      display: inline-flex;
      flex-direction: row;
      font-size: 12px;
      width: 163px;
      margin: 0px 34px;
      justify-content: center;

      .store-attr-tpye {
        color: #000;
      }

      .store-attr-tiem,
      .store-attr-lv {
        color: #989898;
      }

      .store-attr-lv {
        margin-right: 9px;
      }

      .store-attr-tiem {
        margin-left: 9px;
      }
    }

    .store-evaluate {
      height: 30px;
      width: 100%;

      .store-score {
        height: 100%;
        width: 100%;

        text {
          fill: #8b8b8b;
        }

        image[class*='active-img'] {
          display: none;
        }

        @for $i from 1 through 5 {
          &.active-#{$i} {
            @for $k from 1 through $i {
              .active-img-#{$k} {
                display: inline-block;
              }
            }
          }
        }

        .score {
          font-size: 14px;
        }
      }
    }

    .store-button {
      width: 100%;
      height: 44px;
      display: inline-flex;
      position: absolute;
      bottom: 0;
      left: 0;
      .store-in,
      .store-cs {
        width: 116.5px;
        height: 100%;
        border: 0px;
        background: #f5f5f5;
        border-top: 1px solid #d1d1d1;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:focus {
          outline: none;
        }

        color: #777777;

        &:before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          background: transparent no-repeat;
          background-size: 100% 100%;
          margin-top: 3px;
          margin-right: 5px;
        }
        img {
          width: 15px;
          height: 15px;
        }
        div {
          margin-left: 5px;
        }
      }

      // .store-in {
      //   &::before {
      //     background-image: url('../../../assets/order/store-in.png');
      //   }
      // }

      // .store-cs {
      //   &::before {
      //     background-image: url('../../../assets/order/store-cs.png');
      //   }

      //   border-right: 0px;
      // }
    }
  }

  .store-wares {
    height: 100%;
    flex: 100;
    border: 1px solid #dddddd;
    border-left: 0px;
    padding: 0px 10px;

    .top {
      .menu {
        height: 30px;
        width: 100%;
        margin: 13px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .qiehuan {
          height: 80px;
          display: flex;
          align-items: center;
          cursor: default;
          .daohang {
            font-size: 15px;
            color: #272727;
            margin-right: 20px;
            cursor: pointer;
          }
          .bian {
            color: #1a4fff;
            border: 1px solid #1a4fff;
            padding: 0 3px;
            font-size: 14px;
          }
        }
        .piliang {
          cursor: pointer;
          font-size: 14px;
          color: #333;
        }
      }
    }

    .store-wares-content {
      width: 100%;
      height: 168px;
      // display: grid;
      // justify-content: space-around;
      // grid-template-columns: repeat(auto-fill, 133px);
      // grid-gap: 10px 0px;
      .flex-item {
        display: flex;
      }
      .wares-item {
        border: #e2ecf6 1px solid;
        display: inline-block;
        width: 140px;
        height: 160px;
        margin-left: 5px;
        &-img {
          width: 140px;
          height: 80px;
          // padding: 0px 35px;
          padding-top: 5px;
          background: #e2ecf6;
          cursor: pointer;
          > img {
            width: 100%;
            height: 100%;
          }
        }

        &-title {
          height: 78.5px;
          width: 140px;

          &-text {
            width: 92%;
            margin: 10px auto;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            cursor: pointer;
          }

          &-mony {
            color: #e58426;
            width: 95%;
            margin: auto;
            font-weight: 700;

            &:before {
              content: '￥';
            }
          }
        }
      }
    }
  }
}
</style>
